export default [
  {
    path: '/transaction',
    name: 'list-transaction',
    component: () => import('@/views/transaction/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.transaction',
      resource: 'transaction',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listTransaction',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transaction/:id',
    name: 'view-transaction',
    component: () => import('@/views/transaction/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.transaction',
      navActiveLink: 'list-transaction',
      resource: 'transaction',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listTransaction',
          to: { name: 'list-transaction' },
        },
        {
          text: 'breadcrumb.viewTransaction',
          active: true,
        },
      ],
    },
  },
]
