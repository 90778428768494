import Client from '@/repositories/Clients/AxiosClient'
import Config from '@/repositories/Clients/AxiosDefaultConfig'

const resource = `${Config.basePath}/v1/admin/manual-top-up`

export default {
  update(payload) {
    return Client.post(`${resource}`, payload)
  },
}
