export default [
  {
    path: '/package/mlbb',
    name: 'list-mlbb-package',
    component: () => import('@/views/package/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      resource: 'mlbb-package',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/mlbb/create',
    name: 'create-mlbb-package',
    component: () => import('@/views/package/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-mlbb-package',
      resource: 'mlbb-package',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-mlbb-package' },
        },
        {
          text: 'breadcrumb.createPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/mlbb/:id/update',
    name: 'update-mlbb-package',
    component: () => import('@/views/package/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-mlbb-package',
      resource: 'mlbb-package',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-mlbb-package' },
        },
        {
          text: 'breadcrumb.updatePackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/mlbb/:id',
    name: 'view-mlbb-package',
    component: () => import('@/views/package/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-mlbb-package',
      resource: 'mlbb-package',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-mlbb-package' },
        },
        {
          text: 'breadcrumb.viewPackage',
          active: true,
        },
      ],
    },
  },
]
