export default [
  {
    path: '/package/blood-strike',
    name: 'list-blood-strike-package',
    component: () => import('@/views/bloodStrike/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      resource: 'blood-strike',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/blood-strike/create',
    name: 'create-blood-strike-package',
    component: () => import('@/views/bloodStrike/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-blood-strike-package',
      resource: 'blood-strike',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-blood-strike-package' },
        },
        {
          text: 'breadcrumb.createPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/blood-strike/:id/update',
    name: 'update-blood-strike-package',
    component: () => import('@/views/bloodStrike/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-blood-strike-package',
      resource: 'blood-strike',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-blood-strike-package' },
        },
        {
          text: 'breadcrumb.updatePackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/blood-strike/:id',
    name: 'view-blood-strike-package',
    component: () => import('@/views/bloodStrike/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-blood-strike-package',
      resource: 'blood-strike',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-blood-strike-package' },
        },
        {
          text: 'breadcrumb.viewPackage',
          active: true,
        },
      ],
    },
  },
]
