export default [
  {
    path: '/banner',
    name: 'list-banner',
    component: () => import('@/views/banner/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.banner',
      resource: 'banner',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.banner',
          active: true,
        },
      ],
    },
  },
]
