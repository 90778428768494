export default [
  {
    path: '/package/honkai-star-rail',
    name: 'list-honkai-star-rail-package',
    component: () => import('@/views/honKaiStarRail/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      resource: 'honkai-star-rail',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/honkai-star-rail/create',
    name: 'create-honkai-star-rail-package',
    component: () => import('@/views/honKaiStarRail/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-honkai-star-rail-package',
      resource: 'honkai-star-rail',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-honkai-star-rail-package' },
        },
        {
          text: 'breadcrumb.createPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/honkai-star-rail/:id/update',
    name: 'update-honkai-star-rail-package',
    component: () => import('@/views/honKaiStarRail/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-honkai-star-rail-package',
      resource: 'honkai-star-rail',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-honkai-star-rail-package' },
        },
        {
          text: 'breadcrumb.updatePackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/honkai-star-rail/:id',
    name: 'view-honkai-star-rail-package',
    component: () => import('@/views/honKaiStarRail/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-honkai-star-rail-package',
      resource: 'honkai-star-rail',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-honkai-star-rail-package' },
        },
        {
          text: 'breadcrumb.viewPackage',
          active: true,
        },
      ],
    },
  },
]
