export default [
  {
    path: '/package/clash-of-clans',
    name: 'list-clash-of-clans-package',
    component: () => import('@/views/clashOfClans/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      resource: 'clash-of-clans',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/clash-of-clans/create',
    name: 'create-clash-of-clans-package',
    component: () => import('@/views/clashOfClans/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-clash-of-clans-package',
      resource: 'clash-of-clans',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-clash-of-clans-package' },
        },
        {
          text: 'breadcrumb.createPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/clash-of-clans/:id/update',
    name: 'update-clash-of-clans-package',
    component: () => import('@/views/clashOfClans/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-clash-of-clans-package',
      resource: 'clash-of-clans',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-clash-of-clans-package' },
        },
        {
          text: 'breadcrumb.updatePackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/clash-of-clans/:id',
    name: 'view-clash-of-clans-package',
    component: () => import('@/views/clashOfClans/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-clash-of-clans-package',
      resource: 'clash-of-clans',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-clash-of-clans-package' },
        },
        {
          text: 'breadcrumb.viewPackage',
          active: true,
        },
      ],
    },
  },
]
