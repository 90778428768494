import Client from '@/repositories/Clients/AxiosClient'
import Config from '@/repositories/Clients/AxiosDefaultConfig'

const resource = `${Config.basePath}/v1/admin/pre_order`

export default {
  index(payload) {
    return Client.get(resource, {
      params: payload,
    })
  },
  show(id, payload = {}) {
    return Client.get(`${resource}/${id}`, payload)
  },
  update(id, payload) {
    return Client.put(`${resource}/${id}`, payload)
  },
  delete(id, payload) {
    return Client.delete(`${resource}/${id}`, payload)
  },
  updateStatus(id, payload) {
    return Client.put(`${resource}/status/${id}`, payload)
  },
  updateAllStatus(payload) {
    return Client.put(`${resource}/status`, payload)
  },
}
