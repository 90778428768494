export default [
  {
    path: '/package/sausage-man',
    name: 'list-sausage-man-package',
    component: () => import('@/views/sausageMan/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      resource: 'sausage-man',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/sausage-man/create',
    name: 'create-sausage-man-package',
    component: () => import('@/views/sausageMan/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-sausage-man-package',
      resource: 'sausage-man',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-sausage-man-package' },
        },
        {
          text: 'breadcrumb.createPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/sausage-man/:id/update',
    name: 'update-sausage-man-package',
    component: () => import('@/views/sausageMan/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-sausage-man-package',
      resource: 'sausage-man',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-sausage-man-package' },
        },
        {
          text: 'breadcrumb.updatePackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/sausage-man/:id',
    name: 'view-sausage-man-package',
    component: () => import('@/views/sausageMan/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-sausage-man-package',
      resource: 'sausage-man',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-sausage-man-package' },
        },
        {
          text: 'breadcrumb.viewPackage',
          active: true,
        },
      ],
    },
  },
]
