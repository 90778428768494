export default [
  {
    path: '/customer',
    name: 'list-customer',
    component: () => import('@/views/customer/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.customer',
      resource: 'customer',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listCustomer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customer/:id/update',
    name: 'update-customer',
    component: () => import('@/views/customer/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.customer',
      navActiveLink: 'list-customer',
      resource: 'customer',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listCustomer',
          to: { name: 'list-customer' },
        },
        {
          text: 'breadcrumb.updateCustomer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customer/:id',
    name: 'view-customer',
    component: () => import('@/views/customer/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.customer',
      navActiveLink: 'list-customer',
      resource: 'customer',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listCustomer',
          to: { name: 'list-customer' },
        },
        {
          text: 'breadcrumb.viewCustomer',
          active: true,
        },
      ],
    },
  },
]
