export default [
  {
    path: '/whole-sale',
    name: 'list-whole-sale',
    component: () => import('@/views/wholeSale/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.wholeSale',
      resource: 'whole-sale',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listWholeSale',
          active: true,
        },
      ],
    },
  },
  {
    path: '/whole-sale/create',
    name: 'create-whole-sale',
    component: () => import('@/views/wholeSale/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.wholeSale',
      navActiveLink: 'list-whole-sale',
      resource: 'whole-sale',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listWholeSale',
          to: { name: 'list-whole-sale' },
        },
        {
          text: 'breadcrumb.createWholeSale',
          active: true,
        },
      ],
    },
  },
  {
    path: '/whole-sale/:id/update',
    name: 'update-whole-sale',
    component: () => import('@/views/wholeSale/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.wholeSale',
      navActiveLink: 'list-whole-sale',
      resource: 'whole-sale',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listWholeSale',
          to: { name: 'list-whole-sale' },
        },
        {
          text: 'breadcrumb.updateWholeSale',
          active: true,
        },
      ],
    },
  },
  {
    path: '/whole-sale/:id/topup',
    name: 'topup-whole-sale',
    component: () => import('@/views/wholeSale/Topup.vue'),
    meta: {
      pageTitle: 'breadcrumb.wholeSale',
      navActiveLink: 'list-whole-sale',
      resource: 'whole-sale',
      action: 'topup',
      breadcrumb: [
        {
          text: 'breadcrumb.listWholeSale',
          to: { name: 'list-whole-sale' },
        },
        {
          text: 'Topup',
          active: true,
        },
      ],
    },
  },
  {
    path: '/whole-sale/:id/deduct',
    name: 'deduct-whole-sale',
    component: () => import('@/views/wholeSale/Deduct.vue'),
    meta: {
      pageTitle: 'breadcrumb.wholeSale',
      navActiveLink: 'list-whole-sale',
      resource: 'whole-sale',
      action: 'deduct',
      breadcrumb: [
        {
          text: 'breadcrumb.listWholeSale',
          to: { name: 'list-whole-sale' },
        },
        {
          text: 'Deduct',
          active: true,
        },
      ],
    },
  },
  {
    path: '/whole-sale/:id',
    name: 'view-whole-sale',
    component: () => import('@/views/wholeSale/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.wholeSale',
      navActiveLink: 'list-whole-sale',
      resource: 'whole-sale',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listWholeSale',
          to: { name: 'list-whole-sale' },
        },
        {
          text: 'breadcrumb.viewWholeSale',
          active: true,
        },
      ],
    },
  },
]
