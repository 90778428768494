export default [
  {
    path: '/manual-pre-order',
    name: 'list-manual-pre-order',
    component: () => import('@/views/manualPreOrder/List.vue'),
    meta: {
      pageTitle: 'Manual Pre Order',
      resource: 'admin-pre-order',
      action: 'read',
      breadcrumb: [
        {
          text: 'Manual Pre Order',
          active: true,
        },
      ],
    },
  },
]
