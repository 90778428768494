import Client from '@/repositories/Clients/AxiosClient'
import Config from '@/repositories/Clients/AxiosDefaultConfig'

const resource = `${Config.basePath}/v1/admin/dashboard`

export default {
  index(payload) {
    return Client.get(resource, {
      params: payload,
    })
  },
  month(payload) {
    return Client.get(resource + '/month', {
      params: payload,
    })
  },

  game(payload) {
    return Client.get(resource + '/game', {
      params: payload,
    })
  },
}
