import Client from '@/repositories/Clients/AxiosClient'
import Config from '@/repositories/Clients/AxiosDefaultConfig'

const resource = `${Config.basePath}/v1/admin/transaction`

export default {
  index(payload) {
    return Client.get(`${resource}`, {
      params: payload,
    })
  },
  show(id, payload = {}) {
    return Client.get(`${resource}/${id}`, payload)
  },
  delete(id, payload) {
    return Client.delete(`${resource}/${id}`, payload)
  },
  report(payload) {
    return Client.get(`${resource}/report`, {
      params: payload,
    })
  },
  pre_order(payload) {
    return Client.get(`${resource}?type=pre_order`, {
      params: payload,
    })
  },
}
