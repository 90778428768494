import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils'
import errors from './routes/errors'
import auth from './routes/auth'
import profile from './routes/profile'
import user from './routes/user'
import role from './routes/role'
import store from './routes/store'
import packages from './routes/package'
import customer from './routes/customer'
import transaction from './routes/transaction'
import banAccount from './routes/banAccount'
import banner from './routes/banner'
import manualTopUp from './routes/manualTopUp'
import preorderConfiguration from './routes/preorderConfiguration'
import pubgmPackage from './routes/pubgmPackage'
import freefireSg from './routes/freefireSg'
import genshinImpact from './routes/genshinImpact'
import preOrder from './routes/preOrder'
import report from './routes/report'
import wholeSale from './routes/wholeSale'
import checkTransaction from './routes/checkTransaction'
import listPartnerTransaction from './routes/listPartnerTransaction'
import gameSetting from './routes/gameSetting'
import clashOfClans from './routes/clashOfClans'
import farlight84 from './routes/farlight84'
import zepeto from './routes/zepeto'
import wildRift from './routes/wildRift'
import sausageMan from './routes/sausageMan'
import identityV from './routes/identityV'
import bloodStrike from './routes/bloodStrike'
import manualPreOrder from './routes/manualPreOrder'
import hayDay from './routes/hayDay'
import honKaiStarRail from './routes/honKaiStarRail'
import freefireThai from './routes/freefireThai'
import growtopia from './routes/growtopia'
import bigo from './routes/bigo'
import honorKing from './routes/honorKing'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'breadcrumb.dashboard',
        resource: 'dashboard',
        breadcrumb: [
          {
            text: 'breadcrumb.dashboard',
            active: true,
          },
        ],
      },
    },
    ...errors,
    ...auth,
    ...profile,
    ...user,
    ...role,
    ...store,
    ...packages,
    ...pubgmPackage,
    ...freefireSg,
    ...freefireThai,
    ...growtopia,
    ...bigo,
    ...genshinImpact,
    ...clashOfClans,
    ...farlight84,
    ...zepeto,
    ...wildRift,
    ...sausageMan,
    ...honKaiStarRail,
    ...bloodStrike,
    ...identityV,
    ...hayDay,
    ...customer,
    ...transaction,
    ...banAccount,
    ...banner,
    ...manualTopUp,
    ...manualPreOrder,
    ...preorderConfiguration,
    ...honorKing,
    ...preOrder,
    ...report,
    ...wholeSale,
    ...checkTransaction,
    ...listPartnerTransaction,
    ...gameSetting,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// Method using PUSH
const RouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return RouterPush.call(this, to).catch((err) => err)
}

// Method using Replace
const RouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(to) {
  return RouterReplace.call(this, to).catch((err) => err)
}

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? 'admin' : null))
  }

  return next()
})

export default router
