export default [
  {
    path: '/package/honor-of-king',
    name: 'list-honor-of-king-package',
    component: () => import('@/views/honorKing/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      resource: 'honor-of-king',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/honor-of-king/create',
    name: 'create-honor-of-king-package',
    component: () => import('@/views/honorKing/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-honor-of-king-package',
      resource: 'honor-of-king',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-honor-of-king-package' },
        },
        {
          text: 'breadcrumb.createPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/honor-of-king/:id/update',
    name: 'update-honor-of-king-package',
    component: () => import('@/views/honorKing/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-honor-of-king-package',
      resource: 'honor-of-king',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-honor-of-king-package' },
        },
        {
          text: 'breadcrumb.updatePackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/honor-of-king/:id',
    name: 'view-honor-of-king-package',
    component: () => import('@/views/honorKing/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-honor-of-king-package',
      resource: 'honor-of-king',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-honor-of-king-package' },
        },
        {
          text: 'breadcrumb.viewPackage',
          active: true,
        },
      ],
    },
  },
]
