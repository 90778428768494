export default [
  {
    path: '/package/farlight84',
    name: 'list-farlight-84-package',
    component: () => import('@/views/farlight84/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      resource: 'farlight-84',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/farlight84/create',
    name: 'create-farlight-84-package',
    component: () => import('@/views/farlight84/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-farlight84-package',
      resource: 'farlight-84',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-farlight84-package' },
        },
        {
          text: 'breadcrumb.createPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/farlight84/:id/update',
    name: 'update-farlight-84-package',
    component: () => import('@/views/farlight84/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-farlight-84-package',
      resource: 'farlight-84',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-farlight-84-package' },
        },
        {
          text: 'breadcrumb.updatePackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/farlight84/:id',
    name: 'view-farlight-84-package',
    component: () => import('@/views/farlight84/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-farlight84-package',
      resource: 'farlight-84',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-farlight-84-package' },
        },
        {
          text: 'breadcrumb.viewPackage',
          active: true,
        },
      ],
    },
  },
]
