export default [
  {
    path: '/banAccount',
    name: 'list-banAccount',
    component: () => import('@/views/banAccount/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.banAccount',
      resource: 'ban-account',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listBanAccount',
          active: true,
        },
      ],
    },
  },
  {
    path: '/banAccount/create',
    name: 'create-banAccount',
    component: () => import('@/views/banAccount/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.banAccount',
      navActiveLink: 'list-banAccount',
      resource: 'ban-account',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listBanAccount',
          to: { name: 'list-banAccount' },
        },
        {
          text: 'breadcrumb.createBanAccount',
          active: true,
        },
      ],
    },
  },
  {
    path: '/banAccount/:id/update',
    name: 'update-banAccount',
    component: () => import('@/views/banAccount/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.banAccount',
      navActiveLink: 'list-banAccount',
      resource: 'ban-account',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listBanAccount',
          to: { name: 'list-banAccount' },
        },
        {
          text: 'breadcrumb.updateBanAccount',
          active: true,
        },
      ],
    },
  },
  {
    path: '/banAccount/:id',
    name: 'view-banAccount',
    component: () => import('@/views/banAccount/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.banAccount',
      navActiveLink: 'list-banAccount',
      resource: 'ban-account',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listBanAccount',
          to: { name: 'list-banAccount' },
        },
        {
          text: 'breadcrumb.viewBanAccount',
          active: true,
        },
      ],
    },
  },
]
