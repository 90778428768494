export default {
  baseUrl: process.env.VUE_APP_API_URL,
  loginEndpoint: '/api/v1/admin/auth/login',
  forgetPasswordEndpoint: '/api/v1/admin/auth/forget-password',
  resetPasswordEndpoint: '/api/v1/admin/auth/reset-password',
  refreshTokenEndpoint: '/api/v1/admin/auth/refresh',
  authorizationKey: 'LHWyx4LjBDnTJkUL',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: '',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
