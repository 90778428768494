export default [
  {
    path: '/checkPayment/check',
    name: 'list-check-transaction',
    component: () => import('@/views/checkTransaction/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.checkAbaTransaction',
      navActiveLink: 'list-check-transaction',
      resource: 'check-transaction',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.checkAbaTransaction',
          to: { name: 'list-check-transaction' },
          active: true,
        },
      ],
    },
  },
  {
    path: '/checkPartner/check',
    name: 'list-check-partner-transaction',
    component: () => import('@/views/checkPartnerTransaction/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.checkPartnerTransaction',
      navActiveLink: 'list-check-partner-transaction',
      resource: 'check-transaction',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.checkPartnerTransaction',
          to: { name: 'list-check-partner-transaction' },
          active: true,
        },
      ],
    },
  },
  {
    path: '/checkReTopUp/check',
    name: 'list-check-retopup-transaction',
    component: () => import('@/views/checkReTopUpTransaction/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.checkReTopupTransaction',
      navActiveLink: 'list-check-retopup-transaction',
      resource: 'check-transaction',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.checkReTopupTransaction',
          to: { name: 'list-check-retopup-transaction' },
          active: true,
        },
      ],
    },
  },
  {
    path: '/checkReTopUp/check-partner',
    name: 'list-retopup-partner-transaction',
    component: () => import('@/views/checkReTopUpPartnerTransaction/List.vue'),
    meta: {
      pageTitle: 'ReTopUp Partner',
      navActiveLink: 'list-retopup-partner-transaction',
      resource: 'check-transaction',
      action: 'read',
      breadcrumb: [
        {
          text: 'ReTopUp Partner',
          to: { name: 'list-retopup-partner-transaction' },
          active: true,
        },
      ],
    },
  },
  {
    path: '/aba/re-topup',
    name: 'aba-callback',
    component: () => import('@/views/abaReTopUp/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.abaCallback',
      navActiveLink: 'aba-callback',
      resource: 'aba-callback',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.abaCallback',
          to: { name: 'aba-callback' },
          active: true,
        },
      ],
    },
  },
]
