export default [
  {
    path: '/package/wild-rift',
    name: 'list-wild-rift-package',
    component: () => import('@/views/wildRift/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      resource: 'wild-rift',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/wild-rift/create',
    name: 'create-wild-rift-package',
    component: () => import('@/views/wildRift/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-wild-rift-package',
      resource: 'wild-rift',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-wild-rift-package' },
        },
        {
          text: 'breadcrumb.createPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/wild-rift/:id/update',
    name: 'update-wild-rift-package',
    component: () => import('@/views/wildRift/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-wild-rift-package',
      resource: 'wild-rift',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-wild-rift-package' },
        },
        {
          text: 'breadcrumb.updatePackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/wild-rift/:id',
    name: 'view-wild-rift-package',
    component: () => import('@/views/wildRift/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-wild-rift-package',
      resource: 'wild-rift',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-wild-rift-package' },
        },
        {
          text: 'breadcrumb.viewPackage',
          active: true,
        },
      ],
    },
  },
]
