export default [
    {
      path: '/package/freefire-sg',
      name: 'list-freefire-sg-package',
      component: () => import('@/views/freefireSgPackage/List.vue'),
      meta: {
        pageTitle: 'breadcrumb.package',
        resource: 'freefire-sg/my-package',
        action: 'read',
        breadcrumb: [
          {
            text: 'breadcrumb.listPackage',
            active: true,
          },
        ],
      },
    },
    {
      path: '/package/freefire-sg/create',
      name: 'create-freefire-sg-package',
      component: () => import('@/views/freefireSgPackage/Create.vue'),
      meta: {
        pageTitle: 'breadcrumb.package',
        navActiveLink: 'list-freefire-sg-package',
        resource: 'freefire-sg/my-package',
        action: 'create',
        breadcrumb: [
          {
            text: 'breadcrumb.listPackage',
            to: { name: 'list-freefire-sg-package' },
          },
          {
            text: 'breadcrumb.createPackage',
            active: true,
          },
        ],
      },
    },
    {
      path: '/package/freefire-sg/:id/update',
      name: 'update-freefire-sg-package',
      component: () => import('@/views/freefireSgPackage/Update.vue'),
      meta: {
        pageTitle: 'breadcrumb.package',
        navActiveLink: 'list-freefire-sg-package',
        resource: 'freefire-sg/my-package',
        action: 'update',
        breadcrumb: [
          {
            text: 'breadcrumb.listPackage',
            to: { name: 'list-freefire-sg-package' },
          },
          {
            text: 'breadcrumb.updatePackage',
            active: true,
          },
        ],
      },
    },
    {
      path: '/package/freefire-sg/:id',
      name: 'view-freefire-sg-package',
      component: () => import('@/views/freefireSgPackage/View.vue'),
      meta: {
        pageTitle: 'breadcrumb.package',
        navActiveLink: 'list-freefire-sg-package',
        resource: 'freefire-sg/my-package',
        action: 'read',
        breadcrumb: [
          {
            text: 'breadcrumb.listPackage',
            to: { name: 'list-freefire-sg-package' },
          },
          {
            text: 'breadcrumb.viewPackage',
            active: true,
          },
        ],
      },
    },
  ]
  