export default [
  {
    path: '/package/freefireThai',
    name: 'list-freefire-th-package',
    component: () => import('@/views/freefireThai/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      resource: 'freefire-th',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/freefireThai/create',
    name: 'create-freefire-th-package',
    component: () => import('@/views/freefireThai/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-freefireThai-package',
      resource: 'freefire-th',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-freefireThai-package' },
        },
        {
          text: 'breadcrumb.createPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/freefireThai/:id/update',
    name: 'update-freefire-th-package',
    component: () => import('@/views/freefireThai/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-freefire-th-package',
      resource: 'freefire-th',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-freefire-th-package' },
        },
        {
          text: 'breadcrumb.updatePackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/freefireThai/:id',
    name: 'view-freefire-th-package',
    component: () => import('@/views/freefireThai/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-freefireThai-package',
      resource: 'freefire-th',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-freefire-th-package' },
        },
        {
          text: 'breadcrumb.viewPackage',
          active: true,
        },
      ],
    },
  },
]
