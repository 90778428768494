export default [
  {
    path: '/package/genshin-impact',
    name: 'list-genshin-impact-package',
    component: () => import('@/views/genshinImpactPackage/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      resource: 'genshin-impact-package',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/genshin-impact/create',
    name: 'create-genshin-impact-package',
    component: () => import('@/views/genshinImpactPackage/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-genshin-impact-package',
      resource: 'genshin-impact-package',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-genshin-impact-package' },
        },
        {
          text: 'breadcrumb.createPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/genshin-impact/:id/update',
    name: 'update-genshin-impact-package',
    component: () => import('@/views/genshinImpactPackage/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-genshin-impact-package',
      resource: 'genshin-impact-package',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-genshin-impact-package' },
        },
        {
          text: 'breadcrumb.updatePackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/genshin-impact/:id',
    name: 'view-genshin-impact-package',
    component: () => import('@/views/genshinImpactPackage/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-genshin-impact-package',
      resource: 'genshin-impact-package',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-genshin-impact-package' },
        },
        {
          text: 'breadcrumb.viewPackage',
          active: true,
        },
      ],
    },
  },
]
