export default [
  {
    path: '/package/pubgm',
    name: 'list-pubgm-package',
    component: () => import('@/views/pubgmPackage/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      resource: 'pubgm-package',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/pubgm/create',
    name: 'create-pubgm-package',
    component: () => import('@/views/pubgmPackage/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-pubgm-package',
      resource: 'pubgm-package',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-pubgm-package' },
        },
        {
          text: 'breadcrumb.createPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/pubgm/:id/update',
    name: 'update-pubgm-package',
    component: () => import('@/views/pubgmPackage/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-pubgm-package',
      resource: 'pubgm-package',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-pubgm-package' },
        },
        {
          text: 'breadcrumb.updatePackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/pubgm/:id',
    name: 'view-pubgm-package',
    component: () => import('@/views/pubgmPackage/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-pubgm-package',
      resource: 'pubgm-package',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-pubgm-package' },
        },
        {
          text: 'breadcrumb.viewPackage',
          active: true,
        },
      ],
    },
  },
]
