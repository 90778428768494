export default [
  {
    path: '/package/growtopia',
    name: 'list-growtopia-package',
    component: () => import('@/views/growtopia/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      resource: 'growtopia',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/growtopia/create',
    name: 'create-growtopia-package',
    component: () => import('@/views/growtopia/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-growtopia-package',
      resource: 'growtopia',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-growtopia-package' },
        },
        {
          text: 'breadcrumb.createPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/growtopia/:id/update',
    name: 'update-growtopia-package',
    component: () => import('@/views/growtopia/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-growtopia-package',
      resource: 'growtopia',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-growtopia-package' },
        },
        {
          text: 'breadcrumb.updatePackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/growtopia/:id',
    name: 'view-growtopia-package',
    component: () => import('@/views/growtopia/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-growtopia-package',
      resource: 'growtopia',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-growtopia-package' },
        },
        {
          text: 'breadcrumb.viewPackage',
          active: true,
        },
      ],
    },
  },
]
