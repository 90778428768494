import ProfileRepository from './Api/ProfileRepository'
import UserRepository from './Api/UserRepository'
import RoleRepository from './Api/RoleRepository'
import PermissionRepository from './Api/PermissionRepository'
import DashboardRepository from './Api/DashboardRepository'
import StoreRepository from './Api/StoreRepository'
import PackageRepository from './Api/PackageRepository'
import CustomerRepository from './Api/CustomerRepository'
import TransactionRepository from './Api/TransactionRepository'
import BanAccountRepository from './Api/BanAccountRepository'
import GameAccountRepository from './Api/GameAccountRepository'
import BannerRepository from './Api/BannerRepository'
import ManualTopUpRepository from './Api/ManualTopUpRepository'
import EventRepository from './Api/EventRepository'
import PreOrderRepository from './Api/PreOrderRepository'
import ReportRepository from './Api/ReportRepository'
import WholeSaleRepository from './Api/WholeSaleRepository'
import AbaTransactionRepository from './Api/AbaTransactionRepository'
import PartnerTransactionRepository from './Api/PartnerTransactionRepository'
import GameTypeRepository from './Api/GameTypeRepository'
import AdminPreOrderRepository from './Api/AdminPreOrderRepository'

const repositories = {
  profile: ProfileRepository,
  user: UserRepository,
  role: RoleRepository,
  permission: PermissionRepository,
  dashboard: DashboardRepository,
  store: StoreRepository,
  package: PackageRepository,
  customer: CustomerRepository,
  transaction: TransactionRepository,
  banAccount: BanAccountRepository,
  gameAccount: GameAccountRepository,
  banner: BannerRepository,
  manualTopUp: ManualTopUpRepository,
  event: EventRepository,
  preOrder: PreOrderRepository,
  report: ReportRepository,
  wholeSale: WholeSaleRepository,
  checkTransaction: AbaTransactionRepository,
  partnerTransaction: PartnerTransactionRepository,
  gameSetting: GameTypeRepository,
  adminPreOrder: AdminPreOrderRepository,
}

export default {
  get: (name) => repositories[name],
}
