import Client from '@/repositories/Clients/AxiosClient'
import Config from '@/repositories/Clients/AxiosDefaultConfig'

const resource = `${Config.basePath}/v1/admin/package`

export default {
  index(payload) {
    return Client.get(`${resource}?game_id=1`, {
      params: payload,
    })
  },
  create(payload) {
    return Client.post(resource, payload)
  },
  show(id, payload = {}) {
    return Client.get(`${resource}/${id}`, payload)
  },
  update(id, payload) {
    return Client.put(`${resource}/${id}`, payload)
  },
  delete(id, payload) {
    return Client.delete(`${resource}/${id}`, payload)
  },
  options(payload) {
    return Client.get(`${resource}/option`, {
      params: payload,
    })
  },

  pubgm(payload) {
    return Client.get(`${resource}?game_id=2`, {
      params: payload,
    })
  },

  freefireSgMy(payload) {
    return Client.get(`${resource}?game_id=3`, {
      params: payload,
    })
  },

  genshinImpact(payload) {
    return Client.get(`${resource}?game_id=4`, {
      params: payload,
    })
  },

  farlight84(payload) {
    return Client.get(`${resource}?game_id=5`, {
      params: payload,
    })
  },

  clashOfClans(payload) {
    return Client.get(`${resource}?game_id=6`, {
      params: payload,
    })
  },

  wildRift(payload) {
    return Client.get(`${resource}?game_id=7`, {
      params: payload,
    })
  },

  zepeto(payload) {
    return Client.get(`${resource}?game_id=8`, {
      params: payload,
    })
  },

  bloodStrike(payload) {
    return Client.get(`${resource}?game_id=9`, {
      params: payload,
    })
  },

  sausageMan(payload) {
    return Client.get(`${resource}?game_id=10`, {
      params: payload,
    })
  },

  identityV(payload) {
    return Client.get(`${resource}?game_id=11`, {
      params: payload,
    })
  },

  hayDay(payload) {
    return Client.get(`${resource}?game_id=12`, {
      params: payload,
    })
  },

  honKaiStarRail(payload) {
    return Client.get(`${resource}?game_id=13`, {
      params: payload,
    })
  },

  freeFireThai(payload) {
    return Client.get(`${resource}?game_id=14`, {
      params: payload,
    })
  },

  growtopia(payload) {
    return Client.get(`${resource}?game_id=15`, {
      params: payload,
    })
  },

  bigo(payload) {
    return Client.get(`${resource}?game_id=16`, {
      params: payload,
    })
  },

  honorKing(payload) {
    return Client.get(`${resource}?game_id=17`, {
      params: payload,
    })
  },
}
