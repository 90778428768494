import Client from '@/repositories/Clients/AxiosClient'
import Config from '@/repositories/Clients/AxiosDefaultConfig'

const resource = `${Config.basePath}/v1/admin/ban_account`

export default {
  index(payload) {
    return Client.get(resource, {
      params: payload,
    })
  },
  create(payload) {
    return Client.post(resource, payload)
  },
  show(id, payload = {}) {
    return Client.get(`${resource}/${id}`, payload)
  },
  update(id, payload) {
    return Client.put(`${resource}/${id}`, payload)
  },
  delete(id, payload) {
    return Client.delete(`${resource}/${id}`, payload)
  },
}
