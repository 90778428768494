export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    component: () => import('@/views/auth/ForgetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: () => import('@/views/auth/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'auth',
      redirectIfLoggedIn: true,
    },
  },
]
