export default [
  {
    path: '/checkPayment/partnerList',
    name: 'list-partner-transaction',
    component: () => import('@/views/listPartnerTransactionFail/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.listTransactionPartner',
      navActiveLink: 'list-partner-transaction',
      resource: 'list-partner-transaction',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listTransactionPartner',
          to: { name: 'list-partner-transaction' },
          active: true,
        },
      ],
    },
  },
]
