import Client from '@/repositories/Clients/AxiosClient'
import Config from '@/repositories/Clients/AxiosDefaultConfig'

const resource = `${Config.basePath}/v1/client/aba`

export default {
  checkTranId(id, gameID, payload) {
    return Client.get(`${resource}/check_tran_id`, {
      params: {
        tran_id: id,
        game_id: gameID,
      },
    })
  },
  abaCallback(payload) {
    return Client.post(`${resource}/test_aba_payment`, payload)
  },
}
