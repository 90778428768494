export default [
  {
    path: '/game-setting',
    name: 'list-game-setting',
    component: () => import('@/views/gameSetting/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.game-setting',
      resource: 'game-type',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listGameSetting',
          active: true,
        },
      ],
    },
  },
]
