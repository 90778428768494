export default [
  {
    path: '/package/hayday',
    name: 'list-hayday-package',
    component: () => import('@/views/hayDay/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      resource: 'hayday',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/hayday/create',
    name: 'create-hayday-package',
    component: () => import('@/views/hayDay/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-hayday-package',
      resource: 'hayday',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-hayday-package' },
        },
        {
          text: 'breadcrumb.createPackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/hayday/:id/update',
    name: 'update-hayday-package',
    component: () => import('@/views/hayDay/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-hayday-package',
      resource: 'hayday',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-hayday-package' },
        },
        {
          text: 'breadcrumb.updatePackage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package/hayday/:id',
    name: 'view-hayday-package',
    component: () => import('@/views/hayDay/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.package',
      navActiveLink: 'list-hayday-package',
      resource: 'hayday',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPackage',
          to: { name: 'list-hayday-package' },
        },
        {
          text: 'breadcrumb.viewPackage',
          active: true,
        },
      ],
    },
  },
]
