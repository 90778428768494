export default [
  {
    path: '/store',
    name: 'list-store',
    component: () => import('@/views/store/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.store',
      resource: 'store',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listStore',
          active: true,
        },
      ],
    },
  },
  {
    path: '/store/create',
    name: 'create-store',
    component: () => import('@/views/store/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.store',
      navActiveLink: 'list-store',
      resource: 'store',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listStore',
          to: { name: 'list-store' },
        },
        {
          text: 'breadcrumb.createStore',
          active: true,
        },
      ],
    },
  },
  {
    path: '/store/:id/update',
    name: 'update-store',
    component: () => import('@/views/store/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.store',
      navActiveLink: 'list-store',
      resource: 'store',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listStore',
          to: { name: 'list-store' },
        },
        {
          text: 'breadcrumb.updateStore',
          active: true,
        },
      ],
    },
  },
  {
    path: '/store/:id',
    name: 'view-store',
    component: () => import('@/views/store/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.store',
      navActiveLink: 'list-store',
      resource: 'store',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listStore',
          to: { name: 'list-store' },
        },
        {
          text: 'breadcrumb.viewStore',
          active: true,
        },
      ],
    },
  },
]
