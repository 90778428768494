import Client from '@/repositories/Clients/AxiosClient'
import Config from '@/repositories/Clients/AxiosDefaultConfig'

const resource = `${Config.basePath}/v1/admin/admin-preorder`

export default {
  upload(payload) {
    return Client.post(`${resource}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  confirm(payload) {
    return Client.post(`${resource}/confirm`, payload)
  },
}
