export default [
  {
    path: '/pre-order',
    name: 'list-pre-order',
    component: () => import('@/views/preOrder/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.preOrder',
      resource: 'pre-order',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPreOrder',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pre-order/:id',
    name: 'view-pre-order',
    component: () => import('@/views/preOrder/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.pre-order',
      navActiveLink: 'list-pre-order',
      resource: 'pre-order',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPreOrder',
          to: { name: 'list-pre-order' },
        },
        {
          text: 'breadcrumb.viewPreOrder',
          active: true,
        },
      ],
    },
  },
]
