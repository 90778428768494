import Client from '@/repositories/Clients/AxiosClient'
import Config from '@/repositories/Clients/AxiosDefaultConfig'

const resource = `${Config.basePath}/v1/admin/banner`

export default {
  index(payload) {
    return Client.get(resource, {
      params: payload,
    })
  },
  update(payload) {
    return Client.put(`${resource}`, payload)
  },
}
