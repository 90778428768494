import Client from '@/repositories/Clients/AxiosClient'
import Config from '@/repositories/Clients/AxiosDefaultConfig'

const resource = `${Config.basePath}/v1/client/check_pending_from_partner`
const reTopUpUrl = `${Config.basePath}/v1/client/retopup`

export default {
  listCheckPending(id, payload) {
    return id
      ? Client.get(`${resource}?tran_id=${id}`, payload)
      : Client.get(`${resource}`, payload)
  },
  reTopUpBatch(payload) {
    return Client.post(`${reTopUpUrl}/manual-retopup`, payload)
  },
  listReTopUpCheck(id, payload) {
    return id
      ? Client.get(`${reTopUpUrl}/fail-after-20-minute?tran_id=${id}`, payload)
      : Client.get(`${reTopUpUrl}/fail-after-20-minute`, payload)
  },
  checkTranId(id, payload) {
    return Client.get(`${reTopUpUrl}/check-order?tran_id=${id}`, payload)
  },

  specialReTopUp(id) {
    return Client.get(`${reTopUpUrl}/special-re-topup?tran_id=${id}`)
  },
}
